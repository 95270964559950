import React from 'react'
import PostListItem from './PostListItem'
import { css } from '@emotion/react'

const postStyles = css`
  @media screen and (max-width: 999px) {
    h2 {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 20px;
    }
  }
`

export default function PostList ({ posts }) {
  return <React.Fragment>
    {posts.map(({ node: post }) => {
      const { frontmatter } = post

      return (
        <div key={frontmatter.date} css={postStyles}>
          <PostListItem {...frontmatter} />
        </div>
      )
    })}
  </React.Fragment>
}

