import React from 'react'
import { graphql } from 'gatsby'

import PostList from '../components/post/PostList'
import Main from '../components/layouts/Main'
import {Seo} from '../components/Seo'

export default function index ({data}) {

  // In case no markdown files present no need for the app to crash
  const posts = data.allMarkdownRemark && data.allMarkdownRemark.edges
    ? data.allMarkdownRemark.edges
    : []

  return (
    <Main>
      <PostList posts={posts}/>
      <Seo />
    </Main>
  )
}

export const query = graphql`
    query IndexQuery {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        path
                        tags
                        excerpt
                    }
                }
            }
        }
    }
`