import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const PostListItemStyles = styled.div`
  h2 {
    font-size: 26px;
  }

  .secondary-info {
    display: flex;
    align-items: center;
  }

  .date {
    font-size: 16px;
    font-weight: 300;
  }

  p {
    margin: 0;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0 0 0 20px;

    li {
      border-radius: 5px;
      border: 1px solid ${props => props.theme.border.primary};
      font-size: 14px;
      margin: 0 4px;
      line-height: 26px;

      &:hover {
        color: #bc49ff;
        border-color: #bc49ff;
      }

      a {
        padding: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    ul {
      display: none;
    }
  }
`

export default function PostListItem ({ path, title, date, tags }) {
  return (
    <PostListItemStyles>
      <h2>
        <Link to={path}>{title}</Link>
      </h2>

      <div className='secondary-info'>
        <p className='date'>{date}</p>

        <ul>
          {tags.map(tag => {
            return (
              <li key={tag}>
                <Link to={`/tags/${tag}`}>{tag}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </PostListItemStyles>
  )
}
