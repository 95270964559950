import React from 'react'
import { css } from '@emotion/react'
import MainLayout from '../layout'
import SpringScrollbars from '../SprintScrollbars/SprintScrollbars'

const styles = css`
  padding: 20px;

  @media screen and (max-width: 999px) {
    padding: 10px;
  }
`

export default function Main ({ children }) {
  return <MainLayout>
    <SpringScrollbars style={{ height: '100vh' }}>
      <div css={styles}>{children}</div>
    </SpringScrollbars>
  </MainLayout>
}
